import React, { useState } from "react";
import {
  ElementsConsumer,
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement,
  AddressElement,
} from "@stripe/react-stripe-js";
// import CardSection from "./CardSection";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createPayment } from "../../store/payments/service";
import { getLocalState } from "../../utils/localStorage";

export const CheckoutForm = (props) => {
  const { dataPaiement, paymentData } = props;
  const useModal = useSelector((state) => state?.modal);
  // const paymentsState = useSelector(state => state?.payments);
  // const {isPaymentLoading,isPaymentCartLoading}=paymentsState
  const stripe = useStripe();
  const elements = useElements();
  // @ts-ignore
  // const dispatch = useDispatch();
  const [message, ] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const currentUser = getLocalState("_currentUser");

  // const navigate = useNavigate()

  // const nextLink=props.nextLink;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/facturations`,
      },
      redirect: "if_required",
    });

    // const card = elements.getElement(CardElement);
    // const result = await stripe.createToken(card);

    if (error) {
      toast.error(error?.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      const data = {
        pack: dataPaiement.id,
        account: currentUser.user_id,
        payment: paymentData.response.payment.id,
        payment_intent: {
          id: paymentData.response.payment.payment_data.payment_intent_id,
          status: paymentData.response.payment.status,
        },
        // payment_method_id: useModal?.data?.payment_method_id,
        // email:useModal?.data?.email,
        // currency: dataPaiement.currency,
        // description:dataPaiement.description,
      };

      // dispatch(Actions.paymentsCartRequest(useModal?.data?.cart_data,data,useModal?.data?.history));
      //  dispatch(Actions.paymentsRequest(data,''));
      const results = await createPayment(data);

      if (results.status === 201 && results.statusText === "Created") {
        toast.success("Paiement réussi!");
        window.location.href = `${window.location.origin}/facturations`;
      }
    }
  };
  return (
    <div>
      <div className="product-info">
        <h4 className="product-price">
          {dataPaiement.amount ?? useModal?.data?.total_amount} FCFA
        </h4>
      </div>
      <form form id="payment-form" onSubmit={handleSubmit}>
        <h3 className="text-lg font-medium">informations</h3>

        <LinkAuthenticationElement />
        {/* <h3 className="text-lg font-medium">Expédition</h3> */}
        <AddressElement options={{ mode: "shipping" }} />
        <h3 className="text-lg font-medium">Paiement</h3>

        <PaymentElement id="payment-element" />

        {/* <CardElement options={cardStyle} onChange={handleChange} /> */}
        <button
          className="btn-pay"
          type="submit"
          disabled={isProcessing || !stripe || !elements}
        >
          {isProcessing ? "Paiement en cours..." : "Payer maintenant"}
        </button>
        {message && (
          <div
            id="payment-message"
            className="text-red-600 text-2xl font-bold my-8 text-center"
          >
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default function InjectedCheckoutForm(props) {
  // console.log(`Checkout`, props);
  const { dataPaiement, paymentData } = props;

  // @ts-ignore
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          nextLink={props?.nextLink}
          stripe={stripe}
          elements={elements}
          dataPaiement={dataPaiement}
          paymentData={paymentData}
        />
      )}
    </ElementsConsumer>
  );
}
