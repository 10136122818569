import axios from "axios";
import authHeader from "../../api/services/token.interceptor";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchSearch = async (search) =>
  await axios.post(
    `${API_URL}/recouv/search`,
    JSON.stringify({ value: search }),
    {
      headers: authHeader(),
    }
  );

export const fetchSearchAlerte = async (search) =>
  await axios.post(
    `${API_URL}/recouv/search-alert`,
    JSON.stringify({ value: search }),
    {
      headers: authHeader(),
    }
  );

export const fetchSearchDetail = async (rccm) =>
  await axios.post(
    `${API_URL}/recouv/detail`,
    JSON.stringify({ value: rccm }),
    {
      headers: authHeader(),
    }
  );

export const fetchSearchDirigeantsDetail = async (paylaod) =>
  await axios.post(`${API_URL}/recouv/dirigeant-detail`, paylaod, {
    headers: authHeader(),
  });

export const fetchSearchDecision = async (url, denomination) =>
  await axios.post(
    `${API_URL}/${url}`,
    JSON.stringify({ value: denomination }),
    {
      headers: authHeader(),
    }
  );

export const paginateFetchSearchDecision = async (url, denomination) =>
  await axios.post(url, JSON.stringify({ value: denomination }), {
    headers: authHeader(),
  });
