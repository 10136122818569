import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as navigations from "../utils/navigation";
import { getLocalState } from "../utils/localStorage";
import { Dom } from "../utils/Dom";
import { LayoutAdminWrapper } from "./LayoutAdmin.style";
import * as Icons from "react-feather";
import { Helmet, HelmetProvider } from "react-helmet-async";

// const getNavLinkClass = (path, isSidemenu) => {
//   return location.pathname === path
//     ? `${isSidemenu ? "side-menu--active" : "menu--active"}`
//     : path.split("/")[1] === location.pathname.split("/")[1]
//     ? `${isSidemenu ? "side-menu--active" : "menu--active"}`
//     : "";
// };
const LayoutAdmin = ({ children }) => {
  const currentLocation = useLocation();
  const currentUser = getLocalState("_currentUser");
  const accessToken = getLocalState("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser || !accessToken) {
      navigate(navigations.LOGIN);
    }
  }, [currentUser, accessToken, navigate]);

  let menuItems = [
    {
      path: navigations.SEARCHS,
      label: "Faire une recherche",
      icon: <Icons.Search />,
    },
    {
      path: navigations.DASHBOARD,
      label: "Mes documents",
      icon: <Icons.Home />,
    },
    { path: navigations.ALERTE, label: "Alertes", icon: <Icons.AlertCircle /> },
    {
      path: navigations.FACTURATIONS,
      label: "Facturations",
      icon: <Icons.FileText />,
    },
    {
      path: navigations.ORGANISATIONS,
      label: "Organisations",
      icon: <Icons.Users />,
    },
    {
      path: navigations.SETTINGS,
      label: "Paramètres",
      icon: <Icons.Settings />,
    },
    { path: navigations.API_KEY, label: "Clés API", icon: <Icons.Key /> },
    { path: navigations.LOG, label: "Journal", icon: <Icons.PieChart /> },
  ];

  menuItems = menuItems.filter((item) => {
    if (currentUser && currentUser.role.includes("user"))
      return item.label !== "Organisations";
    else return item;
  });

  const getNavLinkClass = (path, isSidemenu) =>
    currentLocation.pathname === path
      ? isSidemenu
        ? "side-menu--active"
        : "menu--active"
      : path.split("/")[1] === currentLocation.pathname.split("/")[1]
      ? isSidemenu
        ? "side-menu--active"
        : "menu--active"
      : "";

  const toggleMobileMenu = () => Dom.toggleElement("#mobile-menu-dropdown");

  return (
    <HelmetProvider>
      <Helmet>
        <script src="/assets/dist/js/app.js"></script>
      </Helmet>
      <LayoutAdminWrapper>
        {/* Mobile Menu */}
        <div className="mobile-menu md:hidden">
          <div className="mobile-menu-bar">
            <Link to={navigations.SEARCHS} className="flex mr-auto">
              <img
                alt="Recoov Logo"
                className="w-16"
                src="/assets/images/logo-recoov-white.png"
              />
            </Link>
            <span onClick={toggleMobileMenu} className="cursor-pointer">
              <Icons.BarChart2 className="w-8 h-8 text-white transform -rotate-90" />
            </span>
          </div>
          <ul
            className="border-t border-theme-24 py-5 hidden"
            id="mobile-menu-dropdown"
          >
            {menuItems.map((item, index) => (
              <li key={index} onClick={toggleMobileMenu}>
                <Link
                  to={item.path}
                  className={`menu cursor-pointer ${getNavLinkClass(
                    item.path,
                    false
                  )}`}
                >
                  <div className="menu__icon">{item.icon}</div>
                  <div className="menu__title">{item.label}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Main Layout */}
        <div className="flex">
          {/* Sidebar */}
          <nav className="side-nav">
            <Link
              to={navigations.SEARCHS}
              className="intro-x flex items-center pl-5 pt-4"
            >
              <img
                alt="Recoov Logo"
                className="w-40"
                src="/assets/images/logo-recoov-white.png"
              />
            </Link>
            <div className="side-nav__divider my-6"></div>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.path}
                    className={`side-menu cursor-pointer ${getNavLinkClass(
                      item.path,
                      true
                    )}`}
                  >
                    <div className="side-menu__icon">{item.icon}</div>
                    <div className="side-menu__title">{item.label}</div>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Content Area */}
          <div className="content">
            <div className="top-bar">
              <div className="-intro-x breadcrumb mr-auto hidden sm:flex">
                {/* <Link to={navigations.DASHBOARD}>Mes documents</Link> */}
              </div>
              <div className="intro-x dropdown w-8 h-8">
                <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
                  <img alt="Profile" src="/assets/dist/images/profile-1.jpg" />
                </div>
                <div className="dropdown-box w-56">
                  <div className="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white">
                    <div className="p-4 border-b border-theme-40 dark:border-dark-3">
                      <div className="font-medium">Utilisateur</div>
                      <div className="text-xs text-theme-41 dark:text-gray-600">
                        En ligne
                      </div>
                    </div>
                    <div className="p-2">
                      <Link
                        to={navigations.PROFILE}
                        className="flex items-center block p-2 hover:bg-theme-1"
                      >
                        <Icons.User className="w-4 h-4 mr-2" /> Profil
                      </Link>
                      <Link
                        to={navigations.PASSWORD}
                        className="flex items-center block p-2 hover:bg-theme-1"
                      >
                        <Icons.Lock className="w-4 h-4 mr-2" /> Mot de passe
                      </Link>
                      <Link
                        to=""
                        className="flex items-center block p-2 hover:bg-theme-1"
                      >
                        <Icons.HelpCircle className="w-4 h-4 mr-2" /> Aide
                      </Link>
                    </div>
                    <div className="p-2 border-t border-theme-40">
                      <Link
                        to={navigations.LOGOUT}
                        className="flex items-center block p-2 hover:bg-theme-1"
                      >
                        <Icons.ToggleRight className="w-4 h-4 mr-2" /> Se
                        déconnecter
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </LayoutAdminWrapper>
    </HelmetProvider>
  );
};

export default LayoutAdmin;
